<script>
import debounce from 'lodash/debounce';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import isDate from 'date-fns/isDate';
import parseISO from 'date-fns/parseISO';
import conditionalGroupReset from '@/mixins/conditionalGroupReset';
// import Datepicker from 'vuejs-datepicker';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';

export default {
	data() {
		return {
			localValue: undefined,
			format:'MM-DD-YYYY',
		};
	},
	components: {
		// Datepicker,
		DatePicker,
	},
	computed: {
		parsedDate() {
			if (this.isInputComplete) {
				return parse(this.localValue, 'LL-dd-yyyy', new Date());
			}
			return null;
		},
		isInputComplete() {
			return this.localValue && this.localValue.length === 10;
		},
		isValidDate() {
			return isValid(this.parsedDate);
		},
		guidanceDate() {
			return format(this.parsedDate, 'MMMM do, yyyy');
		},
		inputFormattedDate() {
			const date = isDate(this.value) ? this.value : parseISO(this.value);

			return isValid(date) ? format(date, 'LL-dd-yyyy') : undefined;
		},
	},
	methods: {
		updateValue() {
			this.localValue = this.inputFormattedDate;
		},
		change: debounce(function () {
			this.$emit(
				'input',
				this.isValidDate ? this.parsedDate.toISOString() : undefined
			);
		}, 300),
	},
	watch: {
		localValue() {
			if (this.localValue && this.localValue.length != 10) {
				this.localValue = moment(this.localValue).format("MM-DD-YYYY");
			}
			this.change();
		},
	},
	mounted() {
		this.updateValue();
	},
	mixins: [conditionalGroupReset],
	props: {
		label: String,
		value: [Date, String],
		testid: String,
		disabled:Boolean
	},
};
</script>

<template>
	<div class="app-input-group address-input">
		<b-row>
			<b-col class="label">{{ label }}</b-col>
		</b-row>
		<b-row no-gutters class="align-items-center">
			<b-col cols="5" md="4" class="input col-7 col-sm-5">
				<!-- <b-form-input
					v-model="localValue"
					v-mask="'##-##-####'"
					placeholder="mm-dd-yyyy"
					:data-testid="testid"
				/> -->
				<!-- <datepicker
					v-model="localValue"
					input-class="form-control"
					:format="format"
					placeholder="mm-dd-yyyy"
					:typeable="true"
					:id="testid"
				/> -->

				<date-picker 
				v-model="localValue"
				input-class="form-control"
				:format="format"
				placeholder="mm-dd-yyyy"
				:input-attr="{ id: testid }"
				value-type="format"
				class="date-input"
				:disabled="disabled"
				type="date"></date-picker>
			</b-col>
			<b-col cols="*" class="ml-2 guidance">
				<span v-if="isValidDate" :data-testid="testid">{{ guidanceDate }}</span
				><span v-else></span>
			</b-col>
		</b-row>
	</div>
</template>
<style>
.date-input{
	width: 100% !important;
}
</style>