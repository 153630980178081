<script>
import allProvinces from 'provinces';
import filter from 'lodash/filter';

const provinces = filter(allProvinces, { country: 'CA' });

export default {
	props: {
		value: {
			type: Object,
			default() {
				return {
					street: '',
					province: '',
					city: '',
				};
			},
		},
		testid:String,
		disabled:Boolean
	},
	data() {
		return {
			provinces,
		};
	},
};
</script>

<template>
	<div class="app-input-group address-input">
		<b-row>
			<b-col class="label"> Address </b-col>
		</b-row>
		<b-row no-gutters>
			<b-col cols="8" class="input col-11 col-sm-8">
				<b-form-input
					v-model="value.street"
					placeholder="ie: 123 Legal Street"
					:data-testid="testid +'-street-name'"
					:disabled="disabled"
				/>
			</b-col>
		</b-row>
		<b-row no-gutters>
			<b-col  class="input col-5 col-sm-4">
				<b-form-input v-model="value.city" placeholder="City" :data-testid="testid+'-city-name'" :disabled="disabled"/>
			</b-col>
			<b-col  class="input col-6 col-sm-4">
				<b-form-select
					v-model="value.province"
					:options="provinces"
					text-field="name"
					value-field="name"
					:data-testid="testid+'-province'"
					:disabled="disabled"
				>
					<template #first>
						<b-form-select-option :value="undefined" disabled :data-testid="testid+'-provinces'">
							Province
						</b-form-select-option>
					</template>
				</b-form-select>
			</b-col>
		</b-row>
	</div>
</template>
