import store from './../store';
import router from './../router';
import {auth} from './../firebase';

export let currentRoleLevelName ='';
export function allPermissionsAvailable() {
	let currentRoleLevel;
	localStorage.removeItem('clientUserAgreement')
	if(router.currentRoute.name == 'agreement' && store.state.permissions && Object.keys(store.state.permissions)?.length){
		const loggedInEmailID = auth.currentUser?.email
		const userFromAgreementPermissions = Object.values(store.state.permissions).find(({ email }) => email === loggedInEmailID);
		if(userFromAgreementPermissions?.role && store.state?.roleLevels?.length){
			if(userFromAgreementPermissions?.isClientUser && router.currentRoute.params?.id){
				localStorage.setItem('clientUserAgreement',router.currentRoute.params.id)
			}
			if(store.state?.roleLevels)
			currentRoleLevel = store.state.roleLevels.find(({ roleName }) => roleName === userFromAgreementPermissions.role);
		} else {
			currentRoleLevel = store.getters.getCurrentRoleLevel ? store.getters.getCurrentRoleLevel : {};
		}
	} else {
		currentRoleLevel = store.getters.getCurrentRoleLevel ? store.getters.getCurrentRoleLevel : {};//localStorage.getItem('currentRoleLevel') ? JSON.parse(localStorage.getItem('currentRoleLevel')) : {};
	}
	// console.log('router.currentRoute --------- ==>',router.currentRoute);
	// console.log('currentRoleLevel --------- ==>',currentRoleLevel.roleName);//localStorage.getItem('currentRoleLevel') ? JSON.parse(localStorage.getItem('currentRoleLevel')) : {};
	currentRoleLevelName = currentRoleLevel.roleName;
	return currentRoleLevel.permissions;
}

export function permissionAvailable(module) {
	let allPerm = allPermissionsAvailable();
	if(module == 'share-agreement' || module == 'section-basics' ||
	module == 'section-parenting' || module == 'section-child-support' ||
	module == 'section-spousal-support'|| module == 'section-support-additionals'  || module == 'section-property' ||
	module == 'section-final-clauses' || module == 'section-other-partner-details'){
		let builderPresent = allPerm?.length && allPerm.find((perm)=>perm.value == 'builder');
		return builderPresent?.options?.find((perm)=>perm.value == module)
	} else
	return allPerm && allPerm.length && allPerm.find((perm)=>perm.value == module)
}

export function hasViewPermission(module){
	return permissionAvailable(module) ? permissionAvailable(module).hasViewPermission : false;
}

export function hasAddPermission(module){
	return permissionAvailable(module) ? permissionAvailable(module).hasAddPermission : false;
}

export function hasEditPermission(module){
	return permissionAvailable(module) ? permissionAvailable(module).hasEditPermission : false;
}

export function hasDeletePermission(module){
	return permissionAvailable(module) ? permissionAvailable(module).hasDeletePermission : false;
}

export function isClientUserAgreement(){
	return store.state.agreements.find((agr)=> agr.id== router.currentRoute.params.id && agr.isClientUser == true) ? true : false
}
