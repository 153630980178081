<script>
export default {
	props: {
		label: String,
		value: Object,
		partnerId: String,
		testid: String,
		disabled: Boolean
	},
};
</script>

<template>
	<div class="app-input-group party-input">
		<b-row>
			<b-col class="label name-input-label">
				{{ label }}
			</b-col>
		</b-row>
		<b-row :id="partnerId" no-gutters>
			<b-col cols="4" class="input">
				<b-form-input
					:id="`${partnerId}_first_name`"
					v-model="value.name.first"
					name="first_name"
					placeholder="First name"
					:data-testid="testid+'-first-name'"
					:disabled="disabled"
				/>
			</b-col>
			<b-col cols="3" class="input">
				<b-form-input
					:id="`${partnerId}_middle_name`"
					v-model="value.name.middle"
					name="middle_name"
					placeholder="Middle name"
					:data-testid="testid+'-middle-name'"
					:disabled="disabled"
				/>
			</b-col>
			<b-col cols="4" class="input">
				<b-form-input
					:id="`${partnerId}_last_name`"
					v-model="value.name.last"
					name="last_name"
					placeholder="Last Name"
					:data-testid="testid+'-last-name'"
					:disabled="disabled"
				/>
			</b-col>
		</b-row>
		<b-row no-gutters>
			<b-col cols="5" class="input col-11 col-sm-5">
				<b-form-input
					:id="`${partnerId}_preferred_name`"
					v-model="value.name.preferred"
					name="preferred_name"
					placeholder="Preferred name (Optional)"
					:data-testid="testid+'-preferred-name'"
					:disabled="disabled"
				/>
			</b-col>
		</b-row>
	</div>
</template>

<style></style>
