
import { ModalPlugin } from 'bootstrap-vue';
import AsyncComputed from 'vue-async-computed';
import BootstrapVue from 'bootstrap-vue';
import router from './router';
import Vue from 'vue';
import VueMask from 'v-mask';
import Router from 'vue-router';

// import * as Sentry from '@sentry/vue';
// import { Integrations } from '@sentry/tracing';

import { db, auth } from './firebase';
import store from './store';

import App from './App.vue';
import FormComponents from './components/Form';
import Loader from './components/Loader.vue';

import PrimeVue from 'primevue/config';
Vue.use(PrimeVue);

import InputNumber from 'primevue/inputnumber';

import './assets/scss/variables.scss';
import './assets/scss/custom.scss';
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';

import 'primevue/resources/themes/saga-blue/theme.css'; //theme
import 'primevue/resources/primevue.min.css'; //core css
import CKEditor from '@ckeditor/ckeditor5-vue2';

// if (process.env.NODE_ENV === 'production') {
// 	Sentry.init({
// 		Vue,
// 		dsn: 'https://b412013e89a148a7bf989b4b5767a7cc@o544432.ingest.sentry.io/5665657',
// 		integrations: [new Integrations.BrowserTracing()],

// 		// We recommend adjusting this value in production, or using tracesSampler
// 		// for finer control
// 		tracesSampleRate: 1.0,
// 	});
// }

Object.entries(FormComponents).map(([key, value]) => Vue.component(key, value));
Vue.component('Loader', Loader);
Vue.component('InputNumber', InputNumber);

Vue.config.productionTip = false;

Vue.use(ModalPlugin);
Vue.use(AsyncComputed);
Vue.use(BootstrapVue);
Vue.use(VueMask);
Vue.use(Router);
Vue.use( CKEditor );

auth.onAuthStateChanged((user) => {
	if (user) {
		store.dispatch('login', user);
		// Sentry.setUser({ email: user.email });
	} else {
		store.dispatch('resetUser');
		// Sentry.configureScope((scope) => scope.setUser(null));
	}
});

new Vue({
	data: {},
	store,
	router,
	db,
	render: (h) => h(App),
	watch: {
		$route(to, from) {
			// If a user is leaving an agreement clear it
			if (
				from.name === 'agreement' &&
				from.params.id &&
				from.params.id != to.params.id
			) {
				store.dispatch('clearActiveUser', from.params.id);
				store.dispatch('resetAgreement');
			}
		},
	},
}).$mount('#app');
