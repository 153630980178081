var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-input-group"},[_c('party-name-input-group',{staticClass:"mb-3 child-name-label",attrs:{"label":"Child's Name","testid":_vm.testid,"disabled":_vm.disabled},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('date-input',{attrs:{"label":"Birthdate","testid":_vm.testid + '-dob',"disabled":_vm.disabled},model:{value:(_vm.value.birthdate),callback:function ($$v) {_vm.$set(_vm.value, "birthdate", $$v)},expression:"value.birthdate"}}),_c('radio-group',{staticClass:"mb-3",attrs:{"select":"","input-cols":"5","label":"Pronouns","options":[
			{ text: 'He/Him', value: 'male' },
			{ text: 'She/Her', value: 'female' },
			{ text: 'They/Them', value: 'neutral' } ],"testid":_vm.testid + '-pronouns',"disabled":_vm.disabled},model:{value:(_vm.value.pronouns),callback:function ($$v) {_vm.$set(_vm.value, "pronouns", $$v)},expression:"value.pronouns"}}),_c('conditional-group',{staticClass:"mt-3",attrs:{"condition":_vm.age > 19},on:{"reset":function($event){_vm.value.selfSupporting = undefined;
			_vm.value.dependantReason = undefined;}}},[_c('yes-no-choice',{staticClass:"mb-3",attrs:{"label":"Is the child self-supporting?","testid":_vm.testid + '-is-self-supporting',"disabled":_vm.disabled},model:{value:(_vm.value.selfSupporting),callback:function ($$v) {_vm.$set(_vm.value, "selfSupporting", $$v)},expression:"value.selfSupporting"}}),_c('conditional-group',{attrs:{"condition":_vm.value.selfSupporting === false}},[_c('input-group',{attrs:{"input-cols":"8","label":"Child remains dependant because:","reset-undefined":"","testid":_vm.testid + '-dependant-reason',"disabled":_vm.disabled},model:{value:(_vm.value.dependantReason),callback:function ($$v) {_vm.$set(_vm.value, "dependantReason", $$v)},expression:"value.dependantReason"}}),_c('check-group',{staticClass:"mt-3",attrs:{"label":("What Child Support aspects are applicable for " + _vm.childPreferredName),"options":[
					{ text: 'Table Amount', value: 'table-amount', attrs:{testid: _vm.testid+_vm.value} },
					{
						text: 'Special or Extraordinary Expenses',
						value: 'special-extraordinary-expenses',
					} ],"testid":_vm.testid + '-child-support-aspects',"id":_vm.testid + '-child-support-aspects',"disabled":_vm.disabled},model:{value:(_vm.value.applicableChildSupportAspects),callback:function ($$v) {_vm.$set(_vm.value, "applicableChildSupportAspects", $$v)},expression:"value.applicableChildSupportAspects"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }