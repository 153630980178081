<script>
import conditionalGroupReset from '@/mixins/conditionalGroupReset';
import { BIconInfoCircle,BTooltip } from 'bootstrap-vue';
export default {
	mixins: [conditionalGroupReset],
	components:{BIconInfoCircle,BTooltip},
	props: {
		label: String,
		value: Boolean,
		options: {
			type: Array,
			default() {
				return [
					{ value: true, text: 'Yes' },
					{ value: false, text: 'No' },
				];
			},
		},
		cols: {
			type: String,
			default() {
				return '2';
			},
		},
		testid:String,
		onChange: Function,
		disabled:Boolean,
		toolTipText:String
	},
	data() {
		return {
			localValue: undefined,
			showToolTip:true
		};
	},
	watch: {
		localValue() {
			this.$emit('input', this.localValue);
		},
	},
	mounted() {
		this.updateValue();
	},
	methods: {
		updateValue() {
			this.localValue = this.value;
		},
		onChangeByUser(event){
			this.$emit('changeValue', event);
		}
	},
};
</script>

<template>
	<b-form-group :label="label">
		<b-row no-gutters>
			<b-col :class="'col-6 col-sm-'+cols">
				<b-form-select
					v-model="localValue"
					:options="options"
					:on-change="onChange"
					:data-testid="testid"
					@change="onChangeByUser"
					:disabled="disabled"
				/>
			</b-col>
			<b-col :cols="cols" class="info-outer" v-if="toolTipText">
				<b-icon-info-circle id="tooltip-target-yesnochoice"></b-icon-info-circle>
				<b-tooltip target="tooltip-target-yesnochoice">
					{{toolTipText}}
				</b-tooltip>
			</b-col>
		</b-row>
	</b-form-group>
</template>

<style scoped>
.info-outer {
	align-self: center;
    margin-left: 0.5rem;
}
</style>
