<script>
import partnerChoices from '@/mixins/partnerChoices';
import conditionalGroupReset from '@/mixins/conditionalGroupReset';
import { BIconInfoCircle,BTooltip } from 'bootstrap-vue';
export default {
	mixins: [partnerChoices, conditionalGroupReset],
	components:{BIconInfoCircle,BTooltip},
	props: {
		agreementData: Object,
		label: String,
		noneOfTheAbove: Boolean,
		options: {
			type: Array,
			default() {
				return [];
			},
		},
		other: Boolean,
		partner: Boolean,
		select: Boolean,
		value: [String, Number, Boolean, Object],
		inputCols: String,
		testid:String,
		name: String,
		disabled:Boolean,
		toolTipText:String,
		length: String
	},
	data() {
		return {
			localValue: null,
			otherValue: null,
		};
	},
	computed: {
		otherSelected() {
			return this.localValue === 'Other';
		},
		currentValue() {
			return this.otherSelected ? this.otherValue : this.localValue;
		},
		displayOtherValueInput() {
			return this.other && this.otherSelected;
		},
		normalizedOptions() {
			return this.options.map((item) => {
				if (item.text) {
					return item;
				} else {
					return { text: item, value: item };
				}
			});
		},
		localOptions() {
			return [
				...(this.partner ? this.partnerChoices : []),
				...this.normalizedOptions,
				...(this.other ? ['Other'] : []),
				...(this.noneOfTheAbove
					? [{ text: 'None of the Above', value: false }]
					: []),
			];
		},
	},
	watch: {
		currentValue() {
			this.$emit('input', this.currentValue);
		},
		value() {
			this.updateValue();
		},
	},
	mounted() {
		this.updateValue();
	},
	methods: {
		updateValue() {
			const value = this.value;

			// Skip this if other is turned off, or the incoming value is null.
			if (this.other === false || value === null) {
				this.localValue = value;
				return;
			}

			const selectedOption = this.localOptions.find(
				(option) => option.value === value
			);

			if (selectedOption === undefined) {
				this.localValue = 'Other';
				this.otherValue = value;
			} else {
				this.localValue = value;
			}
		},
	},
};
</script>

<template>
	<b-form-group :label="label">
		<b-row no-gutters>
			<b-col :class="(length === 'long' ? 'col-12' : 'col-6') + ' col-sm-' + inputCols">
				<b-form-select
					v-if="select"
					:id="name"
					v-model="localValue"
					:options="localOptions"
					:name="name"
					:data-testid="testid"
					:disabled="disabled"
				/>
				<b-form-radio-group
					v-else
					:id="name"
					v-model="localValue"
					stacked
					:options="localOptions"
					:name="name"
					:data-testid="testid"
					:disabled="disabled"
				/>
				<b-form-input
					v-if="displayOtherValueInput"
					:id="name"
					v-model="otherValue"
					class="mt-2"
					:name="name"
					:data-testid="testid"
					:disabled="disabled"
				/>
			</b-col>
			<b-col :cols="inputCols" class="info-outer" v-if="toolTipText">
				<b-icon-info-circle id="tooltip-target-radiogroup"></b-icon-info-circle>
				<b-tooltip target="tooltip-target-radiogroup">
					{{toolTipText}}
				</b-tooltip>
			</b-col>
		</b-row>
	</b-form-group>
</template>
<style scoped>
.info-outer {
	align-self: center;
    margin-left: 0.5rem;
}
</style>